<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Reporte de Facturas Emitidas</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE GENERAL DE FACTURAS EMITIDAS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="codigo_venta"
              ><strong>C&Oacute;DIGO FACTURA: </strong></label
            >
            <InputNumber
              v-model="codigoFactura"
              placeholder="Código Factura"
              :inputClass="'text-right'"
              :min="0"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="cliente"><strong>NOMBRE DE CLIENTE: </strong></label>
            <AutoComplete
              :dropdown="true"
              field="nombre_cliente"
              v-model="clienteSelected"
              :suggestions="clientesListadoFiltrado"
              @complete="buscarCliente($event)"
              placeholder="Buscar Cliente"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="user_id"><strong>USUARIO/CAJERO: </strong></label>
            <Dropdown
              v-model="userSeleccionado"
              :options="users"
              optionLabel="name"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="estado"><strong>ESTADO DE FACTURA: </strong></label>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="medico_id"><strong>MÉDICO: </strong></label>
            <Dropdown
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="nombre_paquete"><strong>PAQUETES: </strong></label>
            <Dropdown
              v-model="paqueteSelected"
              :options="paquetes"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_inicio"
              ><strong>FECHA VENTA DESDE: </strong></label
            >
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_fin"><strong>FECHA VENTA HASTA: </strong></label>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for=""><strong>ACCIONES: </strong></label>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="dtFacturasVentas"
          key="id"
          :value="factura_ventas"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Facturacion DescargarPDF' in auth.user.permissions"
                  label="PDF Imprimir"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Facturacion DescargarEXCEL' in auth.user.permissions"
                  label="EXCEL Exportar"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success mr-2"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportFacturas"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Facturacion DescargarEXCEL' in auth.user.permissions"
                  label="EXCEL Exportar SIN"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Exportar Reporte Excel SIN'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportFacturasSIN"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid text-center w-100">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column field="venta_id" header="VENTA ID"></Column>
          <Column field="id" header="NRO.FACT" :sortable="true">
            <template #body="{ data }">
              <span class="text-center">{{ data.id }}</span>
            </template>
          </Column>
          <!--  <Column field="sucursal" header="SUCURSAL">
            {{ data.sucursal }}
          </Column> -->
          <Column field="usuario" header="USUARIO" :sortable="true">
            {{ data.usuario }}
          </Column>

          <Column field="fecha_venta" header="FECHA VENTA" :sortable="true">
          </Column>
          <Column field="nombre_cliente" header="CLIENTE"> </Column>
          <Column field="nit" header="CI/NIT"> </Column>
          <Column
            field="descuento"
            header="DESC."
            class="text-right"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.descuento) }}
            </template>
          </Column>
          <Column
            field="total_cancelar"
            header="TOTAL VENTA"
            class="text-right"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.total_cancelar) }}
            </template>
          </Column>
          <Column field="nombre_medico" header="MÉDICO">
            <template #body="{ data }">
              {{ data.nombre_medico ?? "SIN MÉDICO" }}
            </template>
          </Column>
          <Column field="nombre_paquete" header="PAQUETE">
            <template #body="{ data }">
              {{ data.nombre_paquete ?? "SIN PAQUETE" }}
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span
                class="product-badge status"
                :class="{
                  'status-outofstock': data.estado === 0,
                  'status-instock': data.estado === 1,
                }"
              >
                {{ textoEstado(data.estado) }}
              </span>
            </template>
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                class="p-button-icon-only p-button-raised"
                icon="pi pi-ellipsis-v"
                v-tooltip.top="'Ver Acciones'"
                @click="$refs['menu' + slotProps.data.id].toggle($event)"
              />
              <Menu
                :ref="'menu' + slotProps.data.id"
                :popup="true"
                :model="acciones(slotProps.data)"
              >
              </Menu>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="motivoAnulacionDialog"
          modal
          header="MOTIVO DE ANULACIÓN"
          :style="{ width: '400px' }"
        >
          <div class="p-fluid">
            <div class="p-field">
              <label for="motivo_anulacions"
                ><strong>SELECCIONE MOTIVO ANULACI&Oacute;N:</strong></label
              >
              <Dropdown
                id="motivo_anulacions"
                v-model="motivoAnulacionSelected"
                :options="motivo_anulacions"
                optionLabel="descripcion"
                optionValue="codigoClasificador"
                placeholder="Seleccione Motivo Anulación"
              />
            </div>
            <div class="p-field mt-2">
              <label for="solo_factura"
                ><strong>ANULAR SOLO FACTURA:</strong></label
              >
              <Checkbox
                id="solo_factura"
                class="ml-2 mt-2"
                v-model="solo_factura"
                binary="true"
                true-value="1"
                false-value="0"
              />
            </div>
          </div>
          <div class="flex justify-content-end mt-4">
            <Button
              label="CANCELAR"
              icon="pi pi-times"
              class="p-button-success mr-2 p-button-lg"
              @click="motivoAnulacionDialog = false"
            />
            <Button
              label="ANULAR FACTURA"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg"
              :loading="enviando"
              @click="anularVentaFactura(anularVentaId)"
            />
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import UserService from "@/service/UserService";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import MedicoService from "@/service/MedicoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";

export default {
  data() {
    return {
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      factura_ventas: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      userSeleccionado: 0,
      users: [{ id: 0, name: "TODOS" }],
      medicoSelected: 0,
      medicos: [{ id: 0, nombre_completo: "TODOS" }],
      paqueteSelected: 0,
      paquetes: [{ id: 0, nombre: "TODOS" }],
      generandoPDF: false,
      exportando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      clienteSelected: null,
      clientesListadoFiltrado: [],
      estadoSelected: 2,
      estados: [
        { id: 2, nombre: "TODOS" },
        { id: 1, nombre: "VALIDA" },
        { id: 0, nombre: "ANULADA" },
      ],
      codigoFactura: null,
      motivo_anulacions: [],
      motivoAnulacionSelected: {},
      motivoAnulacionDialog: false,
      anularVentaId: null,
      solo_factura: 0,
    };
  },
  ventaService: null,
  sucursalService: null,
  userService: null,
  clienteService: null,
  auth: null,
  medicoService: null,
  paqueteProductoService: null,
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.userService = new UserService();
    this.clienteService = new ClienteService();
    this.auth = useAuth();
    this.medicoService = new MedicoService();
    this.paqueteProductoService = new PaqueteProductoService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
    this.cargarMedicos();
    this.cargarPaqueteProductos();
    this.cargarMotivoAnulacion();
  },
  methods: {
    cargarMotivoAnulacion() {
      this.ventaService.motivo_anulacions().then((data) => {
        this.motivo_anulacions = data.motivo_anulacions || [];
        this.motivoAnulacionSelected = this.motivo_anulacions.length
          ? this.motivo_anulacions[0].codigoClasificador
          : {};
      });
    },
    acciones(datos) {
      return [
        {
          label: "Ver Factura",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/showFactura/${datos.id}/detalle`,
        },
        {
          label: "Reimprimir Factura",
          icon: "pi pi-print",
          command: () => {
            this.imprimirVenta(datos.venta_id);
          },
        },
        /* {
          label: "Anular Factura",
          icon: "pi pi-times",
          command: () => {
            this.anularVenta(datos.venta_id);
          },
        }, */
        {
          label: "Anular Factura",
          icon: "pi pi-fw pi-trash",
          tooltip: "Anular Factura",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          /* visible: datos.facturado == 1 ? true : false, */
          command: () => this.motivoAnulacionDialogConfirm(datos),
        },
      ];
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    motivoAnulacionDialogConfirm(datos) {
      this.anularVentaId = datos.venta_id;
      this.motivoAnulacionDialog = true;
      this.enviando = false;
      this.solo_factura = 0;
    },
    anularVentaFactura(id) {
      this.enviando = true;
      let datos = {
        id: id,
        motivoAnulacion: this.motivoAnulacionSelected,
        anular_solo_factura: this.solo_factura ? 1 : 0,
      };
      this.ventaService.anularVenta(id, datos).then((response) => {
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
          this.cargarReporteFacturas();
          this.motivoAnulacionDialog = false;
          this.enviando = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
          this.enviando = false;
        }
      });
    },
    /* imprimirVenta(id) {
      this.ventaService
        .imprimirVentaFactura(id)
        .then(() => {})
        .catch((error) => console.log(error));
    }, */
    /* anularVenta(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la venta?",
        header: "Anular Venta",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo Anular",
        rejectLabel: "No, Cancelar",
        accept: () => {
          this.ventaService.anularVenta(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              // hago que aplique el filtro
              this.BuscarFiltro();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    }, */
    textoEstado(estado) {
      if (estado == 0) {
        return "ANULADA";
      } else if (estado == 1) {
        return "VALIDA";
      } else {
        return "PENDIENTE";
      }
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombreConFactura({ texto: event.query })
          .then((response) => {
            this.clientesListadoFiltrado = [...response.clientes];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    exportFacturas() {
      if (this.factura_ventas.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          user_id: this.userSeleccionado,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          cliente_id: this.clienteSelected,
          estado: this.estadoSelected,
          codigoFactura: this.codigoFactura,
          medico_id: this.medicoSelected,
          nombre_paquete: this.paqueteSelected,
        };
        this.ventaService.exportarRFacturas(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    exportFacturasSIN() {
      if (this.factura_ventas.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          user_id: this.userSeleccionado,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          cliente_id: this.clienteSelected,
          estado: this.estadoSelected,
          codigoFactura: this.codigoFactura,
          medico_id: this.medicoSelected,
          nombre_paquete: this.paqueteSelected,
        };
        this.ventaService.exportarRFacturasSIN(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.factura_ventas.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal: this.sucursalSelected,
          user_id: this.userSeleccionado,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          cliente_id: this.clienteSelected ? this.clienteSelected.id : null,
          estado: this.estadoSelected,
          codigoFactura: this.codigoFactura,
          medico_id: this.medicoSelected,
          nombre_paquete: this.paqueteSelected,
        };
        this.ventaService.imprimirRFacturasPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Se genero el PDF correctamente",
            life: 3000,
          });
        });
      } else
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
    },
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        data.forEach((element) => {
          this.users.push(element);
        });
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos;
        this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
      });
    },
    cargarPaqueteProductos() {
      this.paqueteProductoService
        .getPaqueteProductoAll()
        .then((data) => {
          data.paqueteproductos.forEach((element) => {
            this.paquetes.push(element);
          });
        })
        .catch((error) => console.log(error));
    },
    BuscarFiltro() {
      /* alert("ESTA FUNCIONALIDAD NO ESTA DISPONIBLE, ESPERE UNA NUEVA VERSION"); */
      this.factura_ventas = [];
      this.enviando = true;
      this.cargarReporteFacturas();
    },
    cargarReporteFacturas() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        user_id: this.userSeleccionado,
        cliente_id: this.clienteSelected ? this.clienteSelected : null,
        estado: this.estadoSelected,
        codigoFactura: this.codigoFactura,
        medico_id: this.medicoSelected,
        nombre_paquete: this.paqueteSelected,
      };
      this.ventaService
        .filtros_facturas(datos)
        .then((data) => {
          this.factura_ventas = data.factura_ventas;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>
